<template>
  <div class="hospital_my" v-loading="loadingData">
    <el-row :gutter="20">
<!--      <el-col :sm="12" :md="8" :lg="8" :xl="8" v-can="'hospitalPatients.index'">
        <div class="blocks__hostipil box__none mb-3" @click="drawer.create.status = true">
          <i class="add__hostpil icon_block"></i>
          <div class="text_title">{{ $t('message.new_patient') }}</div>
        </div>
      </el-col>-->
      <!-- end el-col -->

      <el-col :sm="12" :md="8" :lg="8" :xl="8" v-can="'patientHistories.index'">
        <router-link :to="{ name: 'hospitalPatient' }" class="link_hoctpil_blocks">
          <div class="blocks__hostipil box__none mb-3">
            <i class="histri__pansent icon_block"></i>
            <div class="text_title">{{ $t('message.patient_history') }}</div>
          </div>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <el-col :sm="12" :md="8" :lg="8" :xl="8" v-can="'nurseOffices.index'">
        <router-link :to="{ name: 'nurseOffice' }" class="link_hoctpil_blocks">
          <div class="blocks__hostipil box__none mb-3">
            <i class="cabinet_nurse icon_block"></i>
            <div class="text_title">{{ $t('message.nurse_office') }}</div>
          </div>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <el-col :sm="12" :md="8" :lg="8" :xl="8" v-can="'nurseOfficePhysiotherapies.index'">
        <router-link :to="{ name: 'nurseOfficePhy' }" class="link_hoctpil_blocks">
          <div class="blocks__hostipil box__none mb-3">
            <i class="cabinet_nurse icon_block"></i>
            <div class="text_title">{{ $t('message.physiotherapy') }}</div>
          </div>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <el-col :sm="12" :md="8" :lg="8" :xl="8" v-can="'schedulesStasionary.index'">
        <router-link :to="{ name: 'schedulesCabinet' }" class="link_hoctpil_blocks">
          <div class="blocks__hostipil box__none mb-3">
            <i class="schedules__icon icon_block"></i>
            <div class="text_title">{{ $t('message.bed_schedules') }}</div>
          </div>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <el-col :sm="12" :md="8" :lg="8" :xl="8" v-can="'defects.index'">
        <router-link :to="{ name: 'defects' }" class="link_hoctpil_blocks">
          <div class="blocks__hostipil box__none mb-3">
            <i class="stop_icon icon_block"></i>
            <div class="text_title">{{ $t('message.defects') }}</div>
          </div>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <el-col :sm="12" :md="8" :lg="8" :xl="8">
        <router-link :to="{ name: 'stockBunk' }" class="link_hoctpil_blocks">
          <div class="blocks__hostipil box__none mb-3">
            <i class="dney_icon icon_block"></i>
            <div class="text_title">{{ $t('message.warehouses_0') }}</div>
          </div>
        </router-link>
      </el-col>
      <!-- end el-col -->

      <el-col :sm="12" :md="8" :lg="8" :xl="8" v-can="'bunks.index'">
        <router-link :to="{ name: 'bunk' }" class="link_hoctpil_blocks">
          <div class="blocks__hostipil box__none mb-3">
            <i class="bunk_icon icon_block"></i>
            <div class="text_title">{{ $t('message.stationary_settings') }}</div>
          </div>
        </router-link>
      </el-col>
      <!-- end el-col -->
    </el-row>

    <!-- <el-drawer title="I am the title" :visible.sync="drawer" size="100%" :with-header="false">
      <CrmCreate />
    </el-drawer>-->
    <el-drawer size="100%" :wrapperClosable="false" :visible.sync="drawer.create.status" :ref="drawer.create.name"
      @opened="drawerOpened(drawer.create.component)" @closed="drawerClosed(drawer.create.component)">
      <crm-create :ref="drawer.create.component" :drawer-name="drawer.create.name"></crm-create>
    </el-drawer>
  </div>
</template>
<script>
import CrmCreate from "./components/crm-create";
import drawer from "@/utils/mixins/drawer";
import list from "@/utils/mixins/list";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [drawer, list],
  name: "hospital",
  components: {
    CrmCreate
  },
  data() {
    return {
      loadingData: false,
      selectedModel: {},
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate"
        },
        update: {
          name: "update",
          status: false,
          component: "componentDrawerUpdate"
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      list: 'hospitalPatients/list',
      columns: "hospitalPatients/columns",
      pagination: "hospitalPatients/pagination",
      filter: "hospitalPatients/filter",
      sort: "hospitalPatients/sort"
    }),
  },
  methods: {
    ...mapActions({
      updateList: 'hospitalPatients/index',
      empty: 'hospitalPatients/empty',
      updateSort: "hospitalPatients/updateSort",
      refreshData: 'hospitalPatients/refreshData',
      updateFilter: "hospitalPatients/updateFilter",
      updateColumn: "hospitalPatients/updateColumn",
      updatePagination: "hospitalPatients/updatePagination",

    }),
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateList(query).then(res => {
          this.loadingData = false
        }).catch(err => {
          this.loadingData = false
        });
      }
    },
    refresh() {
      this.refreshData()
        .then(res => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch(err => {

        })
    },
    emptyModel() {
      this.empty()
    },
  },

};
</script>

